import axios from 'axios';
// config
import { HOST_API } from 'src/config-global';
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

export const axiosInstance = axios.create({
  baseURL: HOST_API,
  // headers: {
  //   'Content-Type': 'multipart/form-data',
  // },
});

axiosInstance.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem('accessToken');
  config.headers.Authorization = `Bearer ${accessToken}`;
  return config;
});

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('user');
      localStorage.removeItem('menuItems');
      window.location.href = paths.auth.login;
    }

    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: '/api/auth/me',
    login: '/api/auth/login',
    register: '/api/auth/register',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
  dashboardCount: '/api/dashboard-count',
  sateWiseCount: '/api/guest-user-statewise',
  carallotmmentupdate: '/api/cars/status/update',
  carallotment: '/api/cars/reservation',
  carmasterlisting: '/api/cars/listing',
  listofvehical: '/api/cars/reservation/lists',
  deligateUserDropdown: '/api/registered/users',
  hotelData: {
    list: '/api/hotel',
    getReservation: '/api/get-reservation',
    createReservation: '/api/make-reservation',
    guestListData: '/api/guest-list',
    getGuestlist: '/api/get-reservation',
    getRoomType: '/api/room-type',
    deleteguest: '/api/deleted-reservation',
    checkOutGuest: '/api/check-out',
    makeReservation: '/api/make-reservation',
    getGuestCount: 'api/get-delegate-count/guest',
    getGuestListDropDown: '/api/guest',
    allocate_hotel: '/api/approve-hotel-allocation',
    allApprovedGuestListExport: '/api/guest-list/export',
  },
  tourDetails: {
    list: '/api/tour-details',
    personData: '/api/get-tour-details',
    dashboard: '/api/get-tour-dashboard',
    tourView: '/api/tour-details',
    tourDashboardExport: '/api/export-tour-dashboard',
  },
  pr_details: {
    list: '/api/pr-details/list',
    update: '/api/pr-details/update',
    users_list: '/api/pr-details/users',
  },
  exhibitors: {
    list: '/api/exhibitors-details',
    detail: '/api/get-exhibitors-details',
    stall_list: '/api/exhibitor-stall-details',
    update_stall: '/api/update-exhibitor-stall',
  },
  delegate: {
    list: '/api/delegate-list',
    details: '/api/delegate',
    access_control: '/api/access-control',
    qrHistory: '/api/user-qr-history',
    getDelegateCount: 'api/get-delegate-count/delegate',
  },
  golfCart: {
    list: '/api/golf-cart',
    reset: `/api/golf-cart`,
  },
  getFilter: '/api/filter-data',
  vehicles: {
    list: '/api/get/all-vehical/list',
    passengers_list: '/api/passenger-details',
    passengers_details: '/api/users/with/alloted-vehical',
    remove_passenger: '/api/remove/users/from/alloted-vehical',
    dropdown_passenger_list: '/api/users-for/vehicle-allotment',
    reservation: '/api/cars/reservation',
    on_demand: {
      list: '/api/car-ondemad-request-list',
      update: '/api/car-ondemad-request-update',
    },
  },
  inquiry: {
    general: '/api/general-inquiry-list',
    sponsorship: '/api/sponsershiplist',
    updateGeneralInquires: '/api/update-general-inquiry',
  },
  sos: {
    list: '/api/sos-list',
    history: '/api/sos-history',
    updateSOS: '/api/update-sos-status',
  },
  finance: {
    accommodationDetails: '/api/accommodation-details',
    exhibitors: '/api/exhibitor-inquiry',
    totalCount: '/api/total',
  },

  live_Streaming: {
    list: '/api/event-details',
    update: '/api/live-stream',
  },

  notification: {
    list: '/api/notifications',
    read: '/api/notifications/read',
  },
  qr_List: '/api/qr-history-list',
  getAllLocations: `/api/location-list`,
  ladiesCommittee: `/api/ladies-data`,
  delegateDesignation: {
    list: '/api/delegate-designation-list',
    update: '/api/user-equivalent',
  },
  reports: {
    accommodationCount: '/api/get-accommodation-count',
  },
  delegateGuestUser: '/api/guest-user',
  registrationKit: {
    list: '/api/kit-list',
    dateWiseCount: '/api/kit-abstract',
  },
};

export const auth = {
  get: axiosInstance.get,
  post: axiosInstance.post,
  put: axiosInstance.put,
  del: axiosInstance.delete,
};
