/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  Button,
  Card,
  Container,
  Link,
  OutlinedInput,
  Pagination,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/system';

import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { getFilteredData } from 'src/api/common/Filters/filter.services';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import { TableNoData, useTable } from 'src/components/table';
import {
  DateTimeFormat,
  currencyFormate,
  currentDateTime,
  getRouteOptions,
  reportFilterOptions,
} from 'src/locales/Utils/utils';
// import { useRouter } from 'src/routes/hooks';
import { getAccommodationCount } from 'src/api/reports/reports.services';
import { paths } from 'src/routes/paths';
import { FILTER_MODULE } from 'src/utils/Constant';
import { getSRNo, hasValue } from 'src/utils/irc.utils';
import getErrorMessage from '../common/getErrorMessage/getErrorMessage';

const TABLE_HEAD = [
  { id: '', label: 'Sr. No', textAlign: 'center' },
  { id: 'irc_no', label: 'IRC Membership No/Unique code', minWidth: '200px' },
  { id: 'deligate_name', label: 'Name', minWidth: '200px' },
  { id: 'designation', label: 'Designation', minWidth: 150 },
  { id: 'mobile_no', label: 'Mobile No.' },
  { id: 'email', label: 'Email ID' },
  { id: 'acc_name_of_spouse', label: 'Name of Spouse', minWidth: '200px' },
  { id: 'acc_age_of_spouse', label: 'Age' },
  { id: 'package_with_tour', label: 'Avail Tour?', textAlign: 'center' },
  { id: 'duration', label: 'Duration', minWidth: '200px' },
  { id: 'tour_route_option', label: 'Tour Type', minWidth: 100 },
  { id: 'total_amount', label: 'Total Amount', minWidth: 200 },
  { id: 'trans_date', label: 'Payment Date', minWidth: 200 },
  { id: 'total_no_person', label: 'Total Persons' },
];

const defaultFilters = {
  // search: null,
  //   status: 2,
  // start_date: null,
  // end_date: null,
};

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
};

const AccommodationReports = () => {
  const table = useTable();
  const settings = useSettingsContext();

  // const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();

  // const denseHeight = table.dense ? 52 : 72;
  const [exportData, setExportData] = useState(false);
  const [tableData, setTableData] = useState([]);

  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();

  const [filters, setFilters] = useState(defaultFilters);

  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    lastPage: 1,
    total: 0,
    per_page: 50,
  });

  // const dataFiltered = applyFilter({
  //   inputData: tableData,
  //   comparator: getComparator(table.order, table.orderBy),
  //   filters,
  // });

  const notFound = !tableData.length;

  const onFilters = useCallback(
    (name, value) => {
      // table.onResetPage();
      setPaginationData({
        currentPage: 1,
        lastPage: 1,
        total: 0,
        per_page: 50,
      });
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const onSort = useCallback(
    (id) => {
      console.log('id: ', id);
      const isAsc = orderBy === id && order === 'asc';
      if (id !== '') {
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(id);
        onFilters('order_type', isAsc ? 'DESC' : 'ASC');
        onFilters('order_by', id);
      }
    },
    [order, orderBy]
  );

  //   const handleViewClick = (data) => {
  //     router.push(paths.irc.finance.accommodationDetails({ id: data?.irc_no }));
  //   };

  const handleFilterPaymentStatus = useCallback(
    (_e, value) => {
      if (value) {
        onFilters('filter_status', value.value);
      } else {
        onFilters('filter_status', null);
      }
    },
    [onFilters]
  );

  // Page Change
  const onChangePage = (_event, newPage) => {
    setPaginationData({
      ...paginationData,
      currentPage: newPage,
    });
  };

  const searchQuery = useCallback(
    _.debounce((val) => {
      onFilters('search', val);
    }, 1000),
    []
  );

  const handleApiCall = useCallback(() => {
    const pageValue = {
      page: paginationData?.currentPage,
    };

    const objHasValue = hasValue(filters);

    getFilteredData(
      {
        filter: {
          ...objHasValue,
          type: 'accommodation',
        },
        ...pageValue,
        export: exportData,
        module: FILTER_MODULE?.REPORTS,

        columns: 1,
      },
      exportData && { responseType: 'blob' }
    )
      .then(async (res) => {
        if (exportData) {
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement('a');
          link.href = url;
          const fileName = `Reports Accommodation Master- ${currentDateTime(new Date())}.xlsx`;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
        } else {
          const data = getSRNo(res?.data?.current_page, res?.data.per_page, res?.data?.data);
          setTableData(data);
          // setTableData(res?.data?.data);
          setPaginationData({
            currentPage: res?.data.current_page,
            lastPage: res?.data.last_page,
            total: res?.data.total,
            per_page: res?.data.per_page,
          });
        }
        setExportData(false);
      })
      .catch((err) => enqueueSnackbar(getErrorMessage(err), { variant: 'error' }))

      .finally(() => setExportData(false));
  }, [paginationData?.currentPage, filters, exportData]);

  useEffect(() => {
    handleApiCall();
  }, [paginationData.currentPage, filters, exportData, handleApiCall]);

  // const handleDelegateClick = (data) => {
  //   router.push(paths.irc.delegates.details({ id: data.id }));
  // };

  const handleAbstractExport = () => {
    getAccommodationCount()
      .then(async (res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement('a');
        link.href = url;
        const fileName = `Reports Accommodation Master Abstract- ${currentDateTime(
          new Date()
        )}.xlsx`;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => enqueueSnackbar(getErrorMessage(err), { variant: 'error' }))

      .finally(() => setExportData(false));
  };
  return (
    <>
      <Helmet>
        <title> Accommodation Reports : IRC Admin</title>
      </Helmet>

      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Reports"
          subHeading="Accommodation Master"
          links={[
            { name: 'Dashboard', href: paths.irc.root },
            { name: 'Reports ' },
            { name: 'Accommodation Master' },
          ]}
          sx={{
            mb: { xs: 2 },
          }}
        />
        <Card sx={{ marginTop: '24px' }}>
          {/* Filters */}

          <Grid
            container
            sx={{
              padding: '15px',
            }}
            spacing={2}
          >
            <Grid item xs={12} md={3}>
              <Autocomplete
                disablePortal
                id="status"
                // eslint-disable-next-line no-nested-ternary
                // value={filters?.status ? getPaymentStatus(filters?.status) : null}
                input={<OutlinedInput label="payment_status" />}
                onChange={handleFilterPaymentStatus}
                options={reportFilterOptions}
                renderInput={(params) => <TextField {...params} label="Report Filter" />}
              />
            </Grid>

            <Grid item xs={12} md={7}>
              <TextField
                fullWidth
                value={filters?.deligate_name}
                onChange={(event) => searchQuery(event.target.value)}
                placeholder="Search..."
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={1}
              sx={{
                display: 'flex',
              }}
            >
              <Button
                variant="contained"
                sx={{ width: '100%' }}
                onClick={() => handleAbstractExport()}
              >
                {/* <Iconify sx={{ marginRight: '5px' }} icon="solar:export-bold" /> */}
                Abstract Export
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              md={1}
              sx={{
                display: 'flex',
              }}
            >
              <Button
                variant="contained"
                sx={{ width: '100%' }}
                onClick={() => setExportData(true)}
              >
                <Iconify sx={{ marginRight: '5px' }} icon="solar:export-bold" />
                Export
              </Button>
            </Grid>
          </Grid>

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <Scrollbar style={{ maxHeight: '60vh' }}>
              <Table stickyHeader size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.align || 'center'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{ width: headCell.width, minWidth: headCell.minWidth }}
                      >
                        {onSort ? (
                          <TableSortLabel
                            hideSortIcon
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={() => onSort(headCell.id)}
                          >
                            {headCell.label}

                            {orderBy === headCell.id ? (
                              <Box sx={{ ...visuallyHidden }}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        ) : (
                          headCell.label
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody
                  sx={{
                    '& .MuiTableCell-root': {
                      textAlign: 'center',
                    },
                  }}
                >
                  {tableData?.map((row) => (
                    <TableRow key={row?.sr_no}>
                      {/* sr no */}
                      <TableCell>{row?.sr_no}</TableCell>
                      {/* Name & IRC Membership No.s */}
                      <TableCell>{row?.irc_no}</TableCell>
                      {/* <TableCell
                        sx={{
                          // cursor: 'pointer',
                          fontWeight: '500',
                          color: (theme) => theme.palette.primary.main,
                          transition: '0.5s',
                          '&:hover': {
                            textDecoration: 'underline',
                          },
                        }}
                        // onClick={() => handleDelegateClick(row)}
                      >
                        <span
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleDelegateClick(row)}
                        >
                          {row.deligate_name || '-'}
                        </span>
                      </TableCell> */}
                      <TableCell
                        sx={{
                          fontWeight: '500',
                          color: (theme) => theme.palette.primary.main,
                          transition: '0.5s',
                          '&:hover': {
                            textDecoration: 'underline',
                          },
                        }}
                      >
                        <Link
                          component="a"
                          href={paths.irc.delegates.details({ id: row.id })}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {row.deligate_name}
                        </Link>
                      </TableCell>

                      {/* Accommodation Fees */}
                      <TableCell>{row?.designation || '-'}</TableCell>
                      {/* No. Of Person */}
                      <TableCell>{row?.mobile_no || '-'}</TableCell>
                      {/* Payment Status */}
                      <TableCell>{row?.email || '-'}</TableCell>
                      <TableCell>{row?.acc_name_of_spouse || '-'}</TableCell>

                      <TableCell>{row?.acc_age_of_spouse || '-'}</TableCell>
                      {/* No. Of Person */}

                      <TableCell sx={{ textTransform: 'capitalize' }}>
                        {row?.package_with_tour || '-'}
                      </TableCell>

                      <TableCell>{row?.duration || '-'}</TableCell>

                      <TableCell>
                        {row?.tour_route_option ? getRouteOptions(row?.tour_route_option) : '-'}
                      </TableCell>

                      <TableCell>{`₹ ${currencyFormate(row?.total_amount)}` || '-'}</TableCell>

                      <TableCell>{DateTimeFormat(row?.trans_date) || '-'}</TableCell>

                      <TableCell>{row?.total_no_person || '-'}</TableCell>
                      {/* <TableCell>{row?.total_no_person || '-'}</TableCell> */}
                    </TableRow>
                  ))}
                  {/* 
                    <TableEmptyRows
                      height={denseHeight}
                      emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                    /> */}
                  <TableNoData notFound={notFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          {/* <TablePagination
              component="div"
              rowsPerPageOptions={[5, 15, 20]}
              count={dataFiltered.length}
              page={table.page}
              rowsPerPage={table.rowsPerPage}
              onPageChange={table.onChangePage}
              onRowsPerPageChange={table.onChangeRowsPerPage}
              sx={{
                borderTopColor: 'transparent',
              }}
            /> */}

          {/* <Pagination
            component="div"
            color="primary"
            sx={{ p: 2, display: 'flex', justifyContent: 'end' }}
            count={paginationData.lastPage}
            onChange={onChangePage}
            page={paginationData?.currentPage}
            variant="text"
          /> */}
          <Grid
            container
            sx={{
              padding: '15px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <p>Total : {paginationData?.total}</p>

            <Pagination
              component="div"
              color="primary"
              sx={{ p: 2, display: 'flex', justifyContent: 'end' }}
              count={paginationData.lastPage}
              page={paginationData.currentPage}
              onChange={onChangePage}
              variant="text"
            />
          </Grid>
        </Card>
      </Container>
    </>
  );
};

export default AccommodationReports;
