import axios from 'axios';
import { HOST_API } from 'src/config-global';
import { auth, endpoints } from 'src/utils/axios';

export const delegateList = async (params) => {
  const url = endpoints.delegate.list;
  const { data } = await auth.get(url, { params });
  return data;
};

export const getDelegateCount = async () => {
  const url = `${endpoints?.delegate?.getDelegateCount}`;
  const { data } = await auth.get(url);
  return data;
};

export const getDelegateDetails = async (params) => {
  const url = `${endpoints.delegate.details}/${params}`;
  const { data } = await auth.get(url);
  return data;
};

export const accessControl = async (val) => {
  const url = endpoints.delegate.access_control;
  const { data } = await auth.post(url, val);
  return data;
};

export const getDelegateQRHistory = async (params) => {
  const url = `${endpoints.delegate.qrHistory}/${params}`;
  const { data } = await auth.get(url);
  return data;
};

export const getDelegateGuestUsers = async (
  params = {
    page: 1,
  },
  configuration
) => {
  const url = `${endpoints.delegateGuestUser}`;
  const { data } = await auth.get(url, { params, ...configuration });
  return data;
};
