const { format, parseISO, differenceInDays, parse } = require('date-fns');

export const DMYDate = (date) =>
  date === undefined || date === null ? '--' : format(new Date(date), 'dd-MM-yyyy');

export const YMDDate = (date) =>
  date === undefined || date === null ? '--' : format(new Date(date), 'yyyy-MM-dd');

// 18:59:23 -->> 06:59 PM
// export const FormatTime = (date) => (date ? format(new Date(`2023.10.06 ${date}`), 'p') : null);
// export const FormatTime = (date) => (date ? format(new Date(`${date}`), 'p') : null);

export const user = JSON.parse(localStorage.getItem('user'));

export const FormatTime = (date) => {
  if (date) {
    const parsedTime = parse(date, 'HH:mm:ss', new Date());
    if (!Number.isNaN(parsedTime.getTime())) {
      return format(parsedTime, 'p');
    }
    return null;
  }
  return null;
};

// export const HourTime24Format = (date) => format(date, 'HH:mm:ss');
export const HourTime24Format = (date) =>
  // date === undefined ? 'undefined' : format(new Date(`2023.10.06 ${date}`), 'HH:mm:ss');
  date === undefined ? 'undefined' : format(new Date(date), 'HH:mm:ss');

export const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
};

export function FilterDataReceiver(value_get) {
  const filter = {
    filter: value_get?.filter,
    module: value_get?.module_name,
    columns: value_get?.columns_id,
  };
  return filter;
}

//  in parameter :  date = 2023-09-30 03:11:21
//  expected Output for Below : 30-09-2023 03:11 AM

export const DateTimeFormat = (date) => {
  if (date) {
    const parsedDate = parseISO(date);
    const formattedDate = format(parsedDate, 'dd-MM-yyyy hh:mm a');
    return formattedDate;
  }
  return '--';
};

// export const countDays = (departure_date, arrival_date) => {
//   if (departure_date && arrival_date) {
//     return Math.abs(differenceInDays(new Date(departure_date), new Date(arrival_date)));
//   }
//   return '-';
// };

export const countDays = (departure_date, arrival_date) => {
  if (departure_date && arrival_date) {
    const departureDate = new Date(departure_date);
    const arrivalDate = new Date(arrival_date);

    if (departureDate.toDateString() === arrivalDate.toDateString()) {
      return 1;
    }
    return Math.abs(differenceInDays(departureDate, arrivalDate));
  }
  return '-';
};

export const currencyFormate = (val) => {
  if (val) {
    const data = Number(val)?.toLocaleString('en-IN', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return data;
  }
  return 0;
};

export const currentDateTime = (date) => format(date, 'dd-MM-yyyy');

// Check if URL
export function isURL(str) {
  const urlPattern = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/;
  return urlPattern.test(str);
}

export function getRouteOptions(status) {
  switch (status) {
    case 'route_option_1':
      return 'Tour 1';
    case 'route_option_2':
      return 'Tour 2';
    case 'route_option_3':
      return 'Tour 3';
    case 'route_option_4':
      return 'Tour 4';
    case 'route_option_5':
      return 'Tour 5';
    case 'route_option_6':
      return 'Tour 6';
    case 'route_option_7':
      return 'Tour 7';
    case 'route_option_8':
      return 'Tour 8';
    case 'route_option_9':
      return 'Tour 9';
    case 'route_option_10':
      return 'Tour 10';
    default:
      return '-';
  }
}

// ! When Update this also Update getPaymentStatus Function
export const paymentFilterOption = [
  { value: 2, label: 'Paid' },
  { value: 3, label: 'Failed' },
  // { value: 4, label: 'Processing' },
  // { value: 5, label: 'Pending' },
  // { value: 1, label: 'Canceled' },
];

export function getPaymentStatus(status) {
  switch (status) {
    case 1:
      return 'Canceled';
    case 2:
      return 'Paid';
    case 3:
      return 'Failed';
    case 4:
      return 'Processing';
    case 5:
      return 'Pending';
    default:
      return '-';
  }
}

// ! When Update This also Update  getPaymentMode function
export const paymentModeFilterOption = [
  { value: 'online', label: 'Online' },
  { value: 'offline', label: 'Offline' },
];

export function getPaymentMode(status) {
  switch (status) {
    case 'online':
      return 'Online';
    case 'offline':
      return 'Offline';
    default:
      return '-';
  }
}

export const reportFilterOptions = [
  { value: 'all', label: 'All' },
  { value: 'accommodation_only', label: 'Accommodation only ' },
  { value: 'accommodation_with_tour', label: 'Accommodation with Tour' },
];

export const RouteOption = [
  {
    label: 'Tour 1',
    value: 'route_option_1',
    duration: '1_day',
  },
  {
    label: 'Tour 2',
    value: 'route_option_2',
    duration: '1_day',
  },
  {
    label: 'Tour 3',
    value: 'route_option_3',
    duration: '1_day',
  },
  {
    label: 'Tour 4',
    value: 'route_option_4',
    duration: '2_day_1_night',
  },
  {
    label: 'Tour 5',
    value: 'route_option_5',
    duration: '2_day_1_night',
  },
  {
    label: 'Tour 6',
    value: 'route_option_6',
    duration: '3_day_2_night',
  },
  {
    label: 'Tour 7',
    value: 'route_option_7',
    duration: '3_day_2_night',
  },
  {
    label: 'Tour 8',
    value: 'route_option_8',
    duration: '3_day_2_night',
  },
  {
    label: 'Tour 9',
    value: 'route_option_9',
    duration: '3_day_2_night',
  },
  {
    label: 'Tour 10',
    value: 'route_option_10',
    duration: '4_day_3_night',
  },
];

export const designationOption = [
  { value: 'sec', label: 'Secretaries' },
  { value: 'ce', label: 'CE' },
  { value: 'se', label: 'SE' },
  { value: 'ee', label: 'EE' },
  { value: 'ae', label: 'AE' },
  { value: 'dee', label: 'DE/JE' },
  { value: 'oth', label: 'OTHER' },
];
export const designationName = {
  secretaries: 'Secretaries',
  ce: 'CE',
  se: 'SE',
  ee: 'EE',
  ae: 'AE',
  de_je: 'DE/JE',
  other: 'Other',
};

export const designationOption_latest = [
  { value: 'sec', label: designationName?.secretaries },
  { value: 'ce', label: designationName?.ce },
  { value: 'se', label: designationName?.se },
  { value: 'ee', label: designationName?.ee },
  { value: 'ae', label: designationName?.ae },
  { value: 'de-je', label: designationName?.de_je },
  { value: 'oth', label: designationName?.other },
];

export function calculateTotalVacantRooms(room2, room3, room4) {
  const numRoom2 = typeof room2 === 'number' ? room2 : 0;
  const numRoom3 = typeof room3 === 'number' ? room3 : 0;
  const numRoom4 = typeof room4 === 'number' ? room4 : 0;

  // Calculate the total number of vacant rooms
  const totalVacantRooms = numRoom2 + numRoom3 + numRoom4;

  return totalVacantRooms;
}
