import { auth, endpoints } from 'src/utils/axios';

export const getAccommodationCount = async () => {
  const url = endpoints.reports.accommodationCount;
  const { data } = await auth.get(url, { responseType: 'blob' });
  return data;
};

export const getArrivalSummaryExport = async () => {
  const url = '/api/arrival-departure/abstract/export?mode=arrival';
  const { data } = await auth.get(url, { responseType: 'blob' });
  return data;
};
